import React from 'react'
import { ContactForm } from 'web-components'
import Layout from '../containers/Layout'
import contact from'../config/text/contact.json'
import resources from '../config/resources.json'

class Contact extends React.Component {
  render () {
    return (
      <Layout heroSrc='heros/bagged-coffee-beans.jpg' heroText={contact.title} title={contact.meta.title} description={contact.meta.description}>
        <div className='container contact'>
          <div className='row'>
            <div className='col-md pb-3'>
              <h4>Send us an Email</h4>
              <ContactForm url={resources.contactForm.submission.url} receiver='kentcoffee@btinternet.com' />
            </div>
            <div className='col-md'>
              <h4>Telephone</h4>
              <p>
                <span>Main Office: {contact.phone.office}</span><br/>
                <span>Enquiries: {contact.phone.mark}</span><br/>
                <span>Technical: {contact.phone.lee}</span>
              </p>
              <h4>Address</h4>
              <p>
                {Object.keys(contact.address).map(item => {
                  return (
                    <React.Fragment>{contact.address[item]}<br/></React.Fragment>
                  )
                })}
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contact
